var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-page-swiper',{staticClass:"home",class:{ mobile: _vm.isMobile },attrs:{"titleList":_vm.titleList}},[(_vm.isCN)?_c('swiper-slide',{staticStyle:{"background-color":"#000000"}},[_c('img',{staticClass:"product-image",attrs:{"src":_vm.oss.the_novel_project_kv,"alt":"THE NOVEL PROJECT"},on:{"click":function($event){_vm.isCN ? _vm.open(_vm.$paths.TheNovelProject) : _vm.open(_vm.$paths.TheNovelProject)}}})]):_vm._e(),(!_vm.isCN)?_c('swiper-slide',{staticStyle:{"background-color":"#000000"}},[_c('img',{staticClass:"product-image",attrs:{"src":_vm.oss.home_am_neon_80,"alt":"AM NEON 80"},on:{"click":function($event){return _vm.open(
          'https://www.indiegogo.com/projects/am-neon-80-the-rgb-king-of-keyboards/coming_soon'
        )}}}),_c('div',{staticClass:"button",on:{"click":function($event){return _vm.open(
          'https://www.indiegogo.com/projects/am-neon-80-the-rgb-king-of-keyboards/coming_soon'
        )}}},[_c('div',{staticClass:"button-text"},[_vm._v("BUY NOW")]),_c('img',{staticClass:"button-arrow",attrs:{"src":"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJoAAAArCAYAAACAaZHHAAAACXBIWXMAACxLAAAsSwGlPZapAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGsSURBVHgB7dzRTcJQFIfxU+O7juAIruAGxAlgAkdAJxEXUDdQN2AERmAC8Pz1hRgk7e2tnnPz/ZJCIPDUj1PS3tQM+AOdHm5tv/SnewMmsDfbnBkwIUXm2w2hYTIe2FqRvVq3OTdgAgeRbfWaiYbqPLD3w8iEiYbaVi/WLX6+yURDTavnI5EJoaGKnZ8e+y0yITSMpsj8/9jDqc8QGkbpE5kQGor1jUwIDUWGRCac3sBgHtncI3sa8h0mGgYpiUyYaOhr62f7Zx7ZhxUgNPSx3X1fUlpboa/1aDPbX/ox9MKQngex8H25tHpGRyadoRk+MOYe2aPVUyUyIbRGRI5MCK0B0SMTQksuQ2RCaIlliUwILalMkQmhJZQtMiG0ZDJGJoSWSNbIhNCSyByZEFoC2SMTQguuhciE0AJrJTIhtKBaikwILaDWIhOWcgfkv/47q+ffIxNCC0g3SNHdeGy8EJEJh86gtOrZd86bb9dWJkxkQmiBjYgtVGRCaMEVxBYuMiG0BAbEFjIyIbQkesQWNjIhtEROxBY6MiG0ZI7EFj4yIbSEDmK7yhCZfAIUv83TuYFaswAAAABJRU5ErkJggg=="}})])]):_vm._e(),_vm._l((_vm.homeProducts),function(product){return _c('swiper-slide',{key:product.code,staticStyle:{"background-color":"#000000"}},[(
        _vm.isMobile &&
          (_vm.isCN
            ? product.cn_home_media_list[1]
            : product.overseas_home_media_list[1])
      )?[(
          (_vm.isCN
            ? product.cn_home_media_list[1]
            : product.overseas_home_media_list[1]
          ).type === 'video'
        )?_c('v-video',{staticClass:"product-image",attrs:{"src":(_vm.isCN
            ? product.cn_home_media_list[1]
            : product.overseas_home_media_list[1]
          ).url,"loop":""}}):_c('img',{staticClass:"product-image",attrs:{"src":(_vm.isCN
            ? product.cn_home_media_list[1]
            : product.overseas_home_media_list[1]
          ).url,"alt":product.code},on:{"click":function($event){return _vm.learnMore(product.code)}}})]:[(
          (_vm.isCN
            ? product.cn_home_media_list[0]
            : product.overseas_home_media_list[0]
          ).type === 'video'
        )?_c('v-video',{staticClass:"product-image",attrs:{"src":(_vm.isCN
            ? product.cn_home_media_list[0]
            : product.overseas_home_media_list[0]
          ).url,"loop":""}}):_c('img',{staticClass:"product-image",attrs:{"src":(_vm.isCN
            ? product.cn_home_media_list[0]
            : product.overseas_home_media_list[0]
          ).url,"alt":product.code},on:{"click":function($event){return _vm.learnMore(product.code)}}})],(![].includes(product.code))?_c('div',{staticClass:"button",on:{"click":function($event){return _vm.onProductClick(product.code)}}},[_c('div',{staticClass:"button-text"},[_vm._v(_vm._s(_vm.buttonText(product.code)))]),_c('img',{staticClass:"button-arrow",attrs:{"src":_vm.images.home_arrow}})]):_vm._e()],2)}),(_vm.isCN)?_c('swiper-slide',{staticStyle:{"background-color":"#000000"}},[_c('img',{staticClass:"product-image",attrs:{"src":_vm.oss.home_am_master_cn,"alt":"AM Master"},on:{"click":function($event){return _vm.open(_vm.$paths.AMMaster)}}})]):_vm._e(),(!_vm.isCN)?_c('swiper-slide',{staticStyle:{"background-color":"#000000"}},[_c('img',{staticClass:"product-image",attrs:{"src":_vm.oss.am_keyboard_assessories_01,"alt":"AM KEYBOARD ASSESSORIES"},on:{"click":function($event){return _vm.open('https://store.angrymiao.com/collections/accessories')}}})]):_vm._e(),_c('swiper-slide',{staticStyle:{"background-color":"#000000"}},[_c('v-video',{staticClass:"home-cv",attrs:{"src":_vm.oss.home_cv,"playback":_vm.isMobile ? _vm.oss.home_cv_playback_mobile : _vm.oss.home_cv_playback,"loop":""}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }